import { SettingsFlow } from "@ory/client";
import { FilterFlowNodes, gridStyle, hasPassword } from "@ory/elements";
import Panel from "../Panel";

export type PasswordSettingsProps = {
  flow: SettingsFlow;
  title: string;
  description?: string;
};

export const PasswordSettingsSection = ({
  flow,
  title,
  description,
}: PasswordSettingsProps): JSX.Element | null => {
  const filter = {
    nodes: flow.ui.nodes,
    groups: "password",
    withoutDefaultGroup: true,
  };
  return hasPassword(flow.ui.nodes) ? (
    <>
      <Panel.Content title={title} description={description}>
        <div className={gridStyle({ gap: 32 })}>
          <FilterFlowNodes
            filter={{ ...filter, excludeAttributeTypes: "submit,button" }}
          />
        </div>
      </Panel.Content>
      <Panel.Footer>
        <FilterFlowNodes
          filter={{ ...filter, attributes: "submit,button" }}
          buttonOverrideProps={{
            fullWidth: false,
            style: { alignItems: "end" },
          }}
        />
      </Panel.Footer>
    </>
  ) : null;
};
