import { SettingsFlow } from "@ory/client";
import { FilterFlowNodes, gridStyle } from "@ory/elements";
import Panel from "../Panel";

export type ProfileSettingsProps = {
  flow: SettingsFlow;
  title: string;
  description?: string;
};

export const ProfileSettingsSection = ({
  flow,
  title,
  description,
}: ProfileSettingsProps): JSX.Element => {
  const filter = { nodes: flow.ui.nodes, groups: "profile" };
  return (
    <>
      <Panel.Content title={title} description={description}>
        <div className={gridStyle({ gap: 32 })}>
          <FilterFlowNodes
            filter={{ ...filter, excludeAttributeTypes: "submit,button" }}
          />
        </div>
      </Panel.Content>
      <Panel.Footer>
        <FilterFlowNodes
          filter={{ ...filter, attributes: "submit,button" }}
          buttonOverrideProps={{
            fullWidth: false,
            style: { justifyContent: "end" },
          }}
        />
      </Panel.Footer>
    </>
  );
};
