import {
  LoginFlow,
  UiNodeInputAttributes,
  UpdateLoginFlowWithOidcMethod,
  UpdateLoginFlowWithPasswordMethod,
} from "@ory/client";
import {
  ButtonLink,
  Card,
  Divider,
  FilterFlowNodes,
  MessageSection,
  NodeMessages,
  formStyle,
  gridStyle,
  isUiNodeInputAttributes,
  typographyStyle,
} from "@ory/elements";
import { useCallback, useEffect, useReducer, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import AccountSelect from "./AccountSelect";
import HGVLogo from "./HGVLogo";
import LoadingSpinner from "./LoadingSpinner";
import { isEmployee, useAuth } from "./authContext";
import { i18n } from "./i18n";
import { sdk, sdkError } from "./sdk";

type View = "email" | "email_with_oidc" | "password";

interface State {
  flow: LoginFlow | null;
  requiresUserConfirmation: boolean;
  isLoggedIn: boolean;
  view: View;
  identifier?: string;
}

type Action =
  | { type: "change_view"; view: View }
  | { type: "set_flow"; flow: LoginFlow }
  | { type: "set_identifier"; identifier?: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "set_flow":
      const requiresUserConfirmation =
        (action.flow.refresh &&
          action.flow.ui.messages?.some((s) => s.id === 1010003)) ??
        false;

      const identifier = action.flow.ui.nodes.find(
        ({ attributes }) =>
          isUiNodeInputAttributes(attributes) &&
          attributes.name === "identifier"
      )?.attributes as UiNodeInputAttributes | undefined;
      const flowIdentifier = identifier?.value || undefined;

      return {
        ...state,
        flow: action.flow,
        identifier: flowIdentifier ?? state.identifier,
        requiresUserConfirmation: requiresUserConfirmation,
        isLoggedIn:
          action.flow.requested_aal === "aal2" ||
          (action.flow.refresh ?? false),
        view:
          requiresUserConfirmation && state.identifier
            ? !isEmployee(state.identifier)
              ? "password"
              : "email_with_oidc"
            : flowIdentifier
            ? "password"
            : state.view,
      };
    case "set_identifier":
      return {
        ...state,
        identifier: action.identifier,
        view:
          action.identifier &&
          isEmployee(action.identifier) &&
          state.requiresUserConfirmation
            ? "email_with_oidc"
            : state.view,
      };
    case "change_view":
      return {
        ...state,
        view: action.view,
        identifier: action.view === "email" ? undefined : state.identifier,
        flow:
          action.view === "email" && state.flow
            ? {
                ...state.flow,
                ui: {
                  ...state.flow.ui,
                  messages: undefined,
                },
              }
            : state.flow,
      };
    default:
      return state;
  }
};

export const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    flow: null,
    requiresUserConfirmation: false,
    isLoggedIn: false,
    view: "email",
    identifier: undefined,
  });

  const navigate = useNavigate();
  const intl = useIntl();
  const auth = useAuth();

  // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        // the flow data contains the form fields, error messages and csrf token
        .getLoginFlow({ id: flowId })
        .then(({ data: flow }) => dispatch({ type: "set_flow", flow: flow }))
        .catch(sdkErrorHandler),
    []
  );

  // initialize the sdkError for generic handling of errors
  const sdkErrorHandler = sdkError(
    getFlow,
    (flow) => dispatch({ type: "set_flow", flow: flow }),
    "/login",
    true
  );

  // Create a new login flow
  const createFlow = () => {
    const aal2 = searchParams.get("aal2");
    const returnTo = searchParams.get("return_to");
    sdk
      // aal2 is a query parameter that can be used to request Two-Factor authentication
      // aal1 is the default authentication level (Single-Factor)
      // we always pass refresh (true) on login so that the session can be refreshed when there is already an active session
      .createBrowserLoginFlow({
        refresh: true,
        aal: aal2 ? "aal2" : "aal1",
        returnTo: returnTo ?? undefined,
      })
      // flow contains the form fields and csrf token
      .then(({ data: flow }) => {
        // Update URI query params to include flow id
        setSearchParams({ ["flow"]: flow.id }, { replace: true });
        // Set the flow data
        dispatch({ type: "set_flow", flow: flow });
      })
      .catch(sdkErrorHandler);
  };

  const createLogoutFlow = () => {
    // here we create a new logout URL which we can use to log the user out
    sdk
      .createBrowserLogoutFlow(undefined, {
        params: {
          return_url: "/",
        },
      })
      .then(({ data }) => setLogoutUrl(data.logout_url));
  };

  const submitPasswordFlow = async (
    body: {
      method: "password";
    } & UpdateLoginFlowWithPasswordMethod
  ) => {
    // something unexpected went wrong and the flow was not set
    if (!state.flow) return navigate("/login", { replace: true });

    setIsSubmitting(true);

    // we submit the flow to Ory with the form data
    await sdk
      .updateLoginFlow({
        flow: state.flow.id,
        updateLoginFlowBody: body,
      })
      .then(() => {
        state.flow!.return_to
          ? (window.location.href = state.flow!.return_to)
          : navigate("/", { replace: true });
      })
      .catch(sdkErrorHandler);

    setIsSubmitting(false);
  };

  const submitOidcFlow = async (
    body: {
      method: "oidc";
    } & UpdateLoginFlowWithOidcMethod
  ) => {
    // something unexpected went wrong and the flow was not set
    if (!state.flow) return navigate("/login", { replace: true });

    setIsSubmitting(true);

    // we submit the flow to Ory with the form data
    await sdk
      .updateLoginFlow({
        flow: state.flow.id,
        updateLoginFlowBody: body,
      })
      .catch(sdkErrorHandler);

    setIsSubmitting(false);
  };

  useEffect(() => {
    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = searchParams.get("flow");
    // the flow already exists
    if (flowId) {
      getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
      return;
    }

    // we assume there was no flow, so we create a new one
    createFlow();
  }, []);

  useEffect(() => {
    if (!auth.session) return;

    createLogoutFlow();

    const sessionIdentifier: string | undefined =
      auth.session?.identity?.traits.email;
    dispatch({ type: "set_identifier", identifier: sessionIdentifier });
  }, [auth.session]);

  return state.flow ? (
    <Card
      className="userAuthCard"
      image={<HGVLogo />}
      heading={
        <>
          <h2 className={typographyStyle({ type: "regular", size: "small" })}>
            {state.requiresUserConfirmation
              ? intl.formatMessage({ id: "login.title-refresh" })
              : state.identifier !== undefined
              ? i18n.welcome
              : intl.formatMessage({ id: "login.title" })}
          </h2>
          {state.identifier && (
            <AccountSelect
              identifier={state.identifier}
              onSelect={() => {
                if (state.isLoggedIn) return;
                dispatch({ type: "change_view", view: "email" });
              }}
            />
          )}
        </>
      }
    >
      <div className={gridStyle({ gap: 32 })}>
        <NodeMessages
          uiMessages={state.flow.ui.messages?.filter(
            ({ id }) => id !== 1010003
          )}
        />
        <Divider />
        <form
          className={formStyle}
          onSubmit={(e) => {
            // Prevent multiple submits
            if (isSubmitting) return;

            e.preventDefault();
            const form = e.currentTarget;
            const formData = new FormData(form);

            const csrfToken = formData.get("csrf_token")?.toString();
            const stateIdentifier = state.identifier;

            switch (state.view) {
              case "email":
                const formIdentifier = formData.get("identifier") as string;
                if (!isEmployee(formIdentifier)) {
                  dispatch({
                    type: "set_identifier",
                    identifier: formIdentifier,
                  });
                  dispatch({
                    type: "change_view",
                    view: "password",
                  });
                } else {
                  submitOidcFlow({
                    csrf_token: csrfToken,
                    method: "oidc",
                    provider: "microsoft",
                    upstream_parameters: {
                      login_hint: formIdentifier,
                    },
                  });
                }
                break;
              case "password":
                submitPasswordFlow({
                  csrf_token: csrfToken,
                  method: "password",
                  identifier: stateIdentifier!,
                  password: formData.get("password") as string,
                });
                break;
              case "email_with_oidc":
                submitOidcFlow({
                  csrf_token: csrfToken,
                  method: "oidc",
                  provider: "microsoft",
                  upstream_parameters: {
                    login_hint: stateIdentifier,
                  },
                });
                break;
            }
          }}
        >
          {/*always add csrf token and other hidden fields to form*/}
          <FilterFlowNodes
            filter={{
              nodes: state.flow.ui.nodes,
              groups: "default", // we only want to map hidden default fields here
              attributes: "hidden",
            }}
            includeCSRF={true}
          />
          <div className={gridStyle({ gap: 32 })}>
            {state.view === "email" && (
              <FilterFlowNodes
                filter={{
                  nodes: state.flow.ui.nodes,
                  groups: "default",
                  excludeAttributeTypes: "hidden",
                }}
              />
            )}

            {state.view === "email_with_oidc" && (
              <FilterFlowNodes
                filter={{
                  nodes: state.flow.ui.nodes,
                  groups: "oidc",
                  excludeAttributeTypes: "hidden",
                }}
              />
            )}

            {state.view === "password" && (
              <div className={gridStyle({ gap: 16 })}>
                <FilterFlowNodes
                  filter={{
                    nodes: state.flow.ui.nodes,
                    attributes: "password",
                    excludeAttributeTypes: "hidden",
                  }}
                />
                <ButtonLink href="/recovery">
                  <FormattedMessage id="login.forgot-password" />
                </ButtonLink>
              </div>
            )}

            <FilterFlowNodes
              filter={{
                nodes: state.flow.ui.nodes,
                groups: "password",
                attributes: "submit",
                excludeAttributeTypes: "hidden",
              }}
              buttonOverrideProps={{
                header:
                  state.view === "email"
                    ? i18n.continue
                    : intl.formatMessage({ id: "login.title" }),
                disabled: isSubmitting,
              }}
            />
          </div>
        </form>
        {state.isLoggedIn &&
          MessageSection({
            text: intl.formatMessage({
              id: "login.logout-label",
            }),
            buttonText: intl.formatMessage({
              id: "login.logout-button",
            }),
            url: logoutUrl,
          })}
      </div>
    </Card>
  ) : (
    <div className="status">
      <LoadingSpinner />
    </div>
  );
};
